import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root',
})
export class GWOService {
  constructor(private http: HttpClient, private loginService: LoginService) { }

  create(body: any): Observable<any> {
    const token = this.loginService.token.getValue();
    const createGWOPath = `${environment.backendServiceURL}/guided-workouts`;
    const options = {
      headers: new HttpHeaders({
        'X-CIYE-AUTH-TOKEN': token,
      }),
    };
    return this.http.post(createGWOPath, body, options);
  }

  update(body: any, id: string): Observable<any> {
    const token = this.loginService.token.getValue();

    const createGWOPath = `${environment.backendServiceURL}/guided-workouts/${id}`;
    const options = {
      headers: new HttpHeaders({
        'X-CIYE-AUTH-TOKEN': token,
      }),
    };
    return this.http.put(createGWOPath, body, options);
  }

  delete(id: string): Observable<any> {
    const token = this.loginService.token.getValue();

    const createGWOPath = `${environment.backendServiceURL}/guided-workouts/${id}`;
    const options = {
      headers: new HttpHeaders({
        'X-CIYE-AUTH-TOKEN': token,
      }),
    };

    return this.http.delete(createGWOPath, options);
  }

  getAllWorkouts(token: string): Observable<any> {

    const getGWOPath = `${environment.backendServiceURL}/guided-workouts`;
    const options = {
      headers: new HttpHeaders({
        'X-CIYE-AUTH-TOKEN': token,
      }),
    };
    return this.http.get(getGWOPath, options);
  }

  getWorkout(token: string, id: string): Observable<any> {
    const getGWOPath = `${environment.backendServiceURL}/guided-workouts/${id}`;
    const options = {
      headers: new HttpHeaders({
        'X-CIYE-AUTH-TOKEN': token,
      }),
    };
    return this.http.get(getGWOPath, options);
  }

  toSeconds(time: string): number {
    if (typeof time === 'string') {
      const timeParts = time.split(':');
      return parseInt(timeParts[0]) * 60 + parseInt(timeParts[1]);
    }
  }

  toSecondsFromHours(time: string): number {
    if (typeof time === 'string') {
      const timeParts = time.split(':');
      return parseInt(timeParts[0]) * 60 * 60 + parseInt(timeParts[1])*60;
    }
  }

  toMinutes(time: number): string {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return `${minutes}:${seconds}`;
  }

  removeEmptyProps(obj) {
    Object.keys(obj).forEach((k) => {
      return (
        (obj[k] === null ||
          (typeof obj[k] === 'string' && obj[k].trim() === '')) &&
        delete obj[k]
      );
    });

    return obj;
  }

  formatWorkoutDates(value: any) {
    value.design100PaceSeconds = this.toSeconds(value.design100PaceSeconds);
    value.estimatedTime = value.estimatedTime ? this.toSecondsFromHours(value.estimatedTime) : value.estimatedTime;
    if (value.workoutSets && value.workoutSets.length) {
      value.workoutSets.forEach((set) => {
        if (set.workoutBlocks && set.workoutBlocks.length) {
          set.workoutBlocks.forEach((block) => {
            if (block.workoutItems && block.workoutItems.length) {
              block.workoutItems.forEach((item) => {
                item.restTime =
                  item.restTime && item.restTime !== ''
                    ? this.toSeconds(item.restTime)
                    : null;
                item.pace =
                  item.pace && item.pace !== ''
                    ? this.toSeconds(item.pace)
                    : null;

                this.removeEmptyProps(item);
              });
            }
          });
        }
      });
    }

    return value;
  }
}
