import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { LoginService } from '../services/login.service';

import 'rxjs/add/operator/switchMap';
import { NbToastrService } from '@nebular/theme';


interface User {
  uid: string;
  superAdmin?: boolean;
}

@Injectable({
  providedIn: 'root',
})

export class GoogleAuthService {
  constructor(private afAuth: AngularFireAuth,
              protected router: Router,
              private afs: AngularFirestore,
              private loginService: LoginService,
              private _toastrService: NbToastrService) {}

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
  }

  private async oAuthLogin(provider) {
    const credential = await this.afAuth.auth.signInWithPopup(provider);
    this.afs.doc<User>(`users/${credential.user.uid}`).snapshotChanges()
      .subscribe((loggedUser) => {
        if (loggedUser.payload.data() && loggedUser.payload.data().superAdmin) {
          credential.user.getIdToken()
            .then(() => {
              this.router.navigate(['/']);
            });
        } else {
          this.loginService.logout();
          this._toastrService.show('Fail', `You don't access rights!`, {
            status: 'danger',
          });
        }
      });
  }

}
