import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable()

export class FirebaseService {
  constructor(private _firestore: AngularFirestore,
              private _firestorage: AngularFireStorage) {}

  getCollection(item: string) {
    return this._firestore.collection(item).snapshotChanges();
  }

  addToCollection(item: string, params) {
    return this._firestore.collection(item).add(params);
  }

  removeFromCollection(item: string, key: string) {
    return this._firestore.collection(item).doc(key).delete();
  }

  editInCollection(item: string, key: string, value) {
    return this._firestore.collection(item).doc(key).set(value);
  }

  uploadFile(file: File, filePath) {
     return this._firestorage.upload(filePath, file);
  }
}
