import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private _firestore: AngularFirestore,
  ) {}

  canActivate(_route: ActivatedRouteSnapshot) {
    return this.afAuth.authState.pipe(
      mergeMap(user => {
        if (user) {
          const userQuery = this._firestore.collection('users').doc(user.uid);
          return userQuery
            .get()
            .toPromise()
            .then(
              response => {
                if (response.data().active) {
                  return true;
                } else {
                  return this.navigateBack();
                }
              },
              () => {
                return this.navigateBack();
              },
            );
        } else {
          return Observable.of(this.navigateBack());
        }
      }),
    );
  }

  navigateBack() {
    this.router.navigate(['/auth/login']);
    return false;
  }
}
