import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';

@Injectable()

export class LoginService {
  user;
  token = new BehaviorSubject(null);
  
  constructor(public afAuth: AngularFireAuth, public router: Router) {}

  login(user: {email: string, password: string}) {
    return this.afAuth.auth.signInWithEmailAndPassword(user.email, user.password);
  }

  getUser(func) {
   return this.afAuth.auth.onAuthStateChanged(func);
  }

  logout() {
    this.afAuth.auth.signOut().then((e) => {
      this.router.navigate(['/auth/login']);
    });
  }
}
