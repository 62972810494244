import { NgModule } from "@angular/core";

import { ThemeModule } from "../../@theme/theme.module";
import {
  NbAlertModule,
  NbCardModule,
  NbButtonModule,
  NbInputModule,
  NbCheckboxModule
} from "@nebular/theme";
import { LoginComponent } from "./login.component";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [
    ThemeModule,
    NbAlertModule,
    NbInputModule,
    NbCheckboxModule,
    NbButtonModule,
    FormsModule
  ],
  declarations: [LoginComponent]
})
export class LoginModule {}
