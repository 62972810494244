import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { map, take, tap } from 'rxjs/operators';

@Injectable()

export class DeauthGuardService implements CanActivate {
  constructor(private afAuth: AngularFireAuth, public router: Router) {}

  canActivate() {
    return this.afAuth.authState.pipe(
      map(user => {
        if (user) {
          this.router.navigate(['/']);
          return false;
        } else {
          return true;
        }
      }),
    );
  }
}
